<script setup lang="ts">
  const isOpen = ref(false);
</script>

<template>
  <div class="map-clinic-select">
    <div class="img"></div>
    <div class="center-block" @click="isOpen=true">
      <span class="center-block-main-text">8 клиник в Москве и МО</span>
      <span class="center-block-select-text">Выбрать клинику</span>
    </div>
    <DoctorContentMapModal v-if="isOpen" @close="isOpen = false" />
  </div>
</template>

<style scoped lang="scss">
.map-clinic-select {
  position: relative;
  display: flex;
  width: 100%;
}
.img {
  width: 100%;
  height: 130px;
  background: url(~/assets/img/doctors/doctor-content-map.webp) lightgray 50% / cover no-repeat;
  border-radius: 30px;
  @media (max-width: 1023px) {
    height: 104px;
  }
}
.center-block {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  width: max-content;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 4px;
  padding: 8px 24px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}
.center-block-main-text {
  color: #666E81;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.center-block-select-text {
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  cursor: pointer;
}
</style>
