<script setup lang="ts">
  const props = defineProps({
    text: {
      type: String,
      required: true
    },
    showWords: {
      type: Number,
      default: 30
    },
    interviews: {
      type: Array,
      default: () => []
    },
    smallImage: {
      type: String,
      default: ''
    },
    typeContent: {
      type: String,
      default: ''
    }
  });
  const showFullText = ref(false);
  const hidingText = ref('');
  const collapsible = ref(true);
  const mobile = ref(true);
  const list = ref(false);
  const doctor = ref<{ photos?: { '120x120'?: string[] } }>({});
  const readMoreText = ref<HTMLElement>({});

  const showingText = computed(() => {
    const multiplier = mobile.value ? 10 : 60;
    if (!props.text) { return ''; }
    let firstWords = props.text.split(/<p[aA-zZ="\'-:\d ;]*>/g)[1];
    if (firstWords) {
      firstWords = firstWords.replace('</p>', '').split(' ');
    } else {
      firstWords = props.text.replace(/<ul[aA-zZ="\'-:\d ;]*>/g, '')
        .replace('</ul>', '')
        .replace('</li>', '')
        .split(/<li[aA-zZ="\'-:\d ;]*>/g);
      list.value = true;
    }
    if (props.typeContent === 'ul') {
      firstWords = props.text.split(/<li[aA-zZ="\'-:\d ;]*>/g);
      list.value = true;
    }

    const showingWords = [];
    const counter = firstWords.length < multiplier ? firstWords.length : multiplier;
    if (props.text.split(' ').length < props.showWords) {
      showFullText.value = true;
      collapsible.value = false;
      return props.text;
    }
    hidingText.value = props.text.split(' ').slice(counter).join(' ');
    if (list.value) {
      showingWords.push('<ul>');
      for (let i = 1; i < 3; i++) {
        showingWords.push('<li>');
        showingWords.push(firstWords[i]);
        showingWords.push('</li>');
      }
      showingWords.push('</ul>');
    } else {
      for (let i = 0; i < counter; i++) {
        showingWords.push(firstWords[i]);
      }
    }
    const dots = list.value ? '' : '...';
    const firstParagraph = `<span class="first-paragraph">${showingWords.join(' ')}${dots}</span>`;
    return showFullText.value ? props.text : firstParagraph;
  });

  const photo = computed(() => {
    if (doctor.value.photos && doctor.value.photos['120x120']) {
      return doctor.value.photos['120x120'][0];
    } else {
      return '/assets/images/photo_soon.png';
    }
  });

  const click = (e: Event) => {
    const target = e.target as HTMLElement;
    const parent = target.parentNode as HTMLElement;
    if (parent.nodeName === 'DETAILS' && !parent.classList.contains('content_wrapper')) {
      if (parent.getAttribute('open')) {
        parent.removeAttribute('open');
      } else {
        parent.setAttribute('open', true);
      }
    }

    if (target.nodeName === 'A') {
      e.preventDefault();
      navigateTo(target.getAttribute('href') as string);
    }
  };

  const collapse = () => {
    showFullText.value = false;
    const element = readMoreText.value;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
</script>

<template>
  <div v-if="props.text">
    <div class="read-more">
      <section
        ref="readMoreText"
        class="read-more__body-text"
        style="scroll-margin-top: 150px"
      >
        <article>
          <div>
            <!--  -->
            <details
              class="content-global-css-wrapper"
              @click.prevent="click"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <summary class="quality-content-wrapper" v-html="showingText">
              </summary>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="hidingText">
              </span>

              <div v-if="interviews.length > 0">
                <DoctorSingleInterview
                  :image="smallImage"
                  :interview="interviews"
                />
              </div>
            </details>
            <div v-if="interviews.length > 0 && showFullText">
              <DoctorSingleInterview
                :image="smallImage"
                :interview="interviews"
              />
            </div>
          </div>
        </article>
      </section>
      <div>
        <button
          v-show="!showFullText"
          class="button gray-color medium full-width-mobile text-semibold"
          href="#"
          @click.prevent="showFullText = true"
        >
          Читать далее
        </button>
        <button
          v-show="showFullText && collapsible"
          style="margin-top: 12px;"
          class="button gray-color medium full-width-mobile text-semibold"
          @click.prevent="collapse"
        >
          Свернуть
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.read-more {
  &.close:before {
    content: '';
    display: block;
    height: 300px;
    width: 100%;
    pointer-events: none;
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }
  &__body {
    max-height: 215px;
    overflow: hidden;
    &.open {
      max-height: none;
    }
  }
  &__button-close, &__button-open {
    margin-top: $margin-4;
    & .icons {
      margin-left: $margin-2;
    }
    cursor: pointer;
    color: $main-color;
  }
  &__button-close-text, &__button-open-text {
    cursor: pointer;
    color: $main-color;
  }
  &__button-open-wide{
    cursor: pointer;
    border: 1px solid $main-color;
    border-radius: 10px;
    height: 45px;
    max-width: 142px;
    margin: 12px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__button-close-text {
    padding-top: $margin-4;
  }
  &__body-text {
    margin-top: $margin-3;
  }
}
details summary::marker {
  content: '';
}
details summary::-webkit-details-marker {
  display:none;
}
</style>
