<script setup lang="ts">
  import { DoctorsService } from '~/EastclinicVueApi';
  import type { IDoctorsRequest } from '~/EastclinicVueApi';

  definePageMeta({
    layout: 'doctors'
  });

  const pageInfoStore = usePageInfoStore();
  const doctorsStore = useDoctorsStore();
  const clinicsStore = useClinicsStore();
  const { currentClinic } = storeToRefs(clinicsStore);
  const { doctorsCacheMap, count } = storeToRefs(doctorsStore);
  const { pageInfo } = storeToRefs(pageInfoStore);

  // Новый schedule
  const devScheduleStore = useDevScheduleStore();
  // Новый schedule

  const page = ref<number>(1);

  const pageUrl = computed<string|undefined>(() => pageInfoStore.pageInfo?.url);

  const fetchDoctors = async () => {
    // Готовим данные
    const req: IDoctorsRequest = {
      page: page.value,
      perPage: 10,
      url: (useRouter())?.currentRoute?.value?.path,
      withSlots30Days: true
    };
    const result = await new DoctorsService().fetch(req);

    if (page.value === 1) {
      doctorsCacheMap.value.clear();
      doctorsStore.setCount(result.count);
      doctorsStore.mainGroupCount = result.mainGroupCount;
      doctorsStore.doctorsCommentsMap.clear();

      devScheduleStore.cleanSchedules();
    }

    devScheduleStore.createSchedules(result.schedule);

    const ids: number[] = [];

    for (let i = 0; i < result.doctors.length; i++) {
      const doctor = result.doctors[i];
      ids.push(doctor.id);
      if (i !== 0) {
        doctor.prevGroup = result.doctors[i - 1].group ?? '';
      }
      if (i === 0 && page.value !== 1) {
        doctor.prevGroup = doctorsStore.lastGroup;
      }
      if (i === 9) {
        doctorsStore.lastGroup = doctor.group ?? '';
      }
      doctorsCacheMap.value.set(doctor.id, doctor);
    }
    if (useContext() === 'operator') {
      doctorsStore.fetchDoctorsComments(ids);
    }
    return true;
  };

  const { status } = await useLazyAsyncData(
    `doctorsList_${pageUrl}`,
    () => fetchDoctors(), {
      watch: [page]
      // server: useContext() !== 'operator'
    }
  );

  const content = computed(() => (pageInfo.value?.content !== '') ? pageInfo.value?.content : pageInfo.value?.legacyContent);
  const article = computed<string>(() => String(pageInfo.value?.article));
  const isClinicInfo = computed(() => pageInfo.value?.id === 6 && !!currentClinic.value);

  //   const exampleMarkdown = `
  // ::PageArticle
  //   :::ArticleDropdown
  //   Что лечит невролог?
  //   #text
  //   Наша клиника предлагает высокопрофессиональное лечение неврологических заболеваний под руководством опытных специалистов.
  //   :::
  //   :::ArticleDropdown
  //   Что лечит невролог?
  //   #text
  //   Наша клиника предлагает высокопрофессиональное лечение неврологических заболеваний под руководством опытных специалистов.
  //   :::

  // #author
  //   ::ArticleAuthor
  //   ---
  //   img: https://eastclinic.ru/assets/resourceimages/16160/_576x576/28d05044b0b506f39bd00ac17f1d4921_576x576.webp
  //   link: /vrachi/klimenko-inna-stanislavovna
  //   ---
  //   Клименко Инна Станиславовна
  //   #specialization
  //   Невролог / Стаж 16 лет / Кандидат медицинских наук
  //   ::
  // ::
  // :PatientHelpForm
  // :QualityInfo
  // :ClinicMap
  // :Licences
  // ::Questions
  //   :::Question
  //   Что лечит невролог?
  //   #text
  //   Наша клиника предлагает высокопрофессиональное лечение неврологических заболеваний под руководством опытных специалистов.
  //   :::
  //   :::Question
  //   Что лечит невролог?
  //   #text
  //   Наша клиника предлагает высокопрофессиональное лечение неврологических заболеваний под руководством опытных специалистов.
  //   :::
  // #resources
  // Родионова Е.Б. Роль биопсии слюнных желез в диагностике системных заболеваний и их лимфопролиферативных осложнений [Электронный ресурс]
  // ::
  // `;

  const tryParseMarkdown = () => {
    if (pageInfo.value?.contentMarkdown.startsWith('::')) {
      return parseMarkdown(pageInfo.value?.contentMarkdown);
    }
    throw new Error('Invalid markdown');
  };

  const { data: ast, error } = await useAsyncData('markdown', tryParseMarkdown);

  const navigationStore = useNavigationStore();

  onMounted(() => {
    navigationStore.cleanTabs();
    const navItems = document.querySelectorAll('*[data-nav]');
    for (const navItem of navItems) {
      navigationStore.navigationTabs.push({
        link: navItem.id,
        title: navItem.getAttribute('data-nav') ?? '',
        isActive: false
      });
    }
  });

  const doctorsEl = ref();

  useIntersectionObserver(
    doctorsEl,
    ([{ isIntersecting }]) => {
      const tab = navigationStore.navigationTabs.find((t) => t.link === 'doctors');
      if (tab && navigationStore.navigationTabs) {
        tab.isActive = isIntersecting;
      }
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
</script>

<template>
  <div>
    <div class="main-container header-padding">
      <h1 v-if="!isClinicInfo" class="main-page-title">
        {{ pageInfo?.pagetitle }}
      </h1>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="pageInfo?.showOldContent === 1 && content && pageInfo?.type !== 'specialist' && pageInfo?.type !== 'service'" class="pt-8 pb-8 content-global-css-wrapper" v-html="content"></div>
      <div v-if="pageInfo?.article && pageInfo?.type === 'service'">
        <div v-if="article">
          <noindex v-if="currentClinic">
            <ReadMore
              :title="pageInfo?.pagetitle"
              :show-words="30"
              :text="article"
            />
          </noindex>
          <ReadMore
            v-else
            :title="pageInfo?.pagetitle"
            :show-words="30"
            :text="article"
          />
        </div>
      </div>
      <div v-if="pageInfo?.type === 'specialist'">
        <div v-if="!isClinicInfo" class="secondary-page-text">
          Точное лечение с использованием передовых методик диагностики и индивидуального подхода в лечении
        </div>
        <NuxtLink v-if="!isClinicInfo" to="#help-form" class="page-title-button">
          Записаться на приём
        </NuxtLink>
        <DoctorContentClinicInfoShort v-if="currentClinic && pageInfo?.type === 'specialist' && pageInfo.id !== 6" :clinic="currentClinic" />
        <DoctorContentTopInfo />
        <!-- <DoctorContentClinicAreas /> -->
        <DoctorContentMapClinicSelectWithBanner />
        <!-- TODO -->
        <!-- <ClientOnly>
          <LazyOperatorEditor v-if="useContext() === 'operator'" />
        </ClientOnly> -->
      </div>
    </div>
    <div
      class="page-wrapper background-gradient"
    >
      <DoctorModals />
      <DoctorList
        id="doctors"
        ref="doctorsEl"
        v-model="page"
        data-nav="Врачи"
        :pending="status === 'pending'"
        :show-toggle="true"
        :total-count="count"
        :doctors-cache-map="doctorsCacheMap"
      />
    </div>
    <div v-if="pageInfo?.contentMarkdown && pageInfo?.type !== 'service' && pageInfo?.type !== 'disease'">
      <MDCRenderer
        v-if="!error && ast?.body"
        :body="ast?.body"
        :data="ast?.data"
      />
      <div v-else>
        <PatientHelpForm />
        <QualityInfo />
        <ClinicMap />
        <Licences />
      </div>
      <!-- <div v-if="pageInfo?.type !== 'doctor' && pageInfo?.type !== 'cabinet'">
        <div class="clinic-areas-title">
          Направления клиники
        </div>
        <DoctorContentClinicAreas />
      </div> -->
    </div>
    <div v-if="pageInfo?.showOldContent === 1 && pageInfo?.type === 'specialist'" class="main-container">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="content" class="pt-8 pb-8 content-global-css-wrapper" v-html="content"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-page-title {
  color: #262633;
  font-family:'Roboto';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.header-padding {
  padding-bottom: 80px;
  @media (max-width: 1023px) {
    padding-bottom: 30px;
  }
}
.secondary-page-text {
  width: 432px;
  margin-top: 16px;
  margin-bottom: 24px;
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.page-title-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 56px;
  padding: 14px 32px;
  border-radius: 15px;
  background-color: #2D7FF9;
  color: white !important;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: 585px) {
    width: 100%;
  }
}
.clinic-areas-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  color: #262633;
  text-align: center;
  font-family: 'Roboto';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
}
</style>
