<script setup lang="ts">
  const { pageInfo } = storeToRefs(usePageInfoStore());
  const { currentClinic } = storeToRefs(useClinicsStore());

  const isClinicInfo = computed(() => {
    return pageInfo.value?.id === 6 && !!currentClinic.value;
  });
</script>

<template>
  <div class="map-clinic-select-with-banner">
    <DoctorContentMapClinicSelect v-if="!isClinicInfo" />
    <DoctorContentBanner />
  </div>
</template>

<style scoped lang="scss">
.map-clinic-select-with-banner {
  display: flex;
  gap: 20px;
  width: 100%;
  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    gap: 40px;
  }
}
</style>
