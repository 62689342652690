<template>
  <div class="top-info-container">
    <div class="top-info-el">
      <IconTopInfoThreeDots filled class="icon" />
      <div class="top-info-text">
        <span class="top-info-title-text">Командная работа врачей</span>
        <span class="top-info-main-text">Совместный подход специалистов для вашего эффективного лечения</span>
      </div>
    </div>
    <div class="top-info-el">
      <IconTopInfoPlace filled class="icon" />
      <div class="top-info-text">
        <span class="top-info-title-text">Все в одном месте</span>
        <span class="top-info-main-text">Полный спектр услуг и диагностики — удобно и быстро</span>
      </div>
    </div>
    <div class="top-info-el">
      <IconTopInfoHuman filled class="icon" />
      <div class="top-info-text">
        <span class="top-info-title-text">Личный координатор лечения</span>
        <span class="top-info-main-text">Организует визиты и контролирует выполнение рекомендаций врача</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.top-info-container {
  display: flex;
  gap: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 24px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.icon {
  font-size: 36px;
  margin: 0;
  min-width: 37px;
}
.top-info-el {
  display: flex;
  gap: 16px;
  width: 376px;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.top-info-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 1023px) {
    gap: 4px;
  }
}
.top-info-title-text {
  color: #262633;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
}
.top-info-main-text {
  color: #666E81;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
