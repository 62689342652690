<script setup lang="ts">
  const award = {
    title: 'Ист Клиника стала Лучшей частной клиникой в Москве',
    platform: 'по версии Гран-При ПроДокторов 2024',
    mainText: 'Для нашей команды это важный знак признания наших усилий, мы всегда стремимся к высокому качеству оказания медицинских услуг.',
    bottomText: 'Премия ПроДокторов — это общенациональная награда, которая присуждается лучшим врачам и клиникам на основании отзывов пользователей портала prodoctorov.ru.',
    img: 7,
    url: 'https://award.prodoctorov.ru/grand-prix/2024/lpus/'
  };

  const showModal = ref(false);

  const openModal = () => {
    showModal.value = true;
  };

  const closeModal = () => {
    showModal.value = false;
  };
</script>

<template>
  <div class="banner-container" @click="openModal">
    <div class="banner-content">
      <span class="banner-main-text">Ист Клиника – Лучшая частная клиника Москвы 2024</span>
      <span class="banner-secondary-text">по версии ПроДокторов</span>
    </div>
    <img class="banner-award-img" src="~/assets/img/main-page/award-item-7.webp">
  </div>
  <AwardsCarouselLayoutModal v-if="showModal" :award="award" @close="closeModal" />
</template>

<style scoped lang="scss">
.banner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  max-height: 128px;
  border-radius: 30px;
  background: linear-gradient(0deg, #F9F6F1 0%, #F9F6F1 100%), #1B1B1B;
  cursor: pointer;
  @media (max-width: 1023px) {
    padding: 20px;
  }
}
.banner-main-text {
  color: #262633;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  max-width: 389px;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
    max-width: 100%;
  }
}
.banner-secondary-text {
  color: #262633;
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }
}
.banner-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}
.banner-award-img {
  width: 108px;
  height: 108px;
  @media (max-width: 1023px) {
    width: 68px;
    height: 68px;
  }
  @media (max-width: 380px) {
    display: none;
  }
}
</style>
